<template>
  <div class="has-text-centered section vh100">
    <i class="p-4 is-large fa-2x fas fa-spinner fa-pulse"></i>
    <h1 v-if="title" class="title">{{ title }}</h1>
    <h2 v-if="subtitle" class="subtitle">{{ subtitle }}</h2>
  </div>
</template>

<script>
export default {
  props: ['title', 'subtitle']
}
</script>

<style>

</style>
