<template>
  <div class="container is-max-desktop">
    <div class="section">
      <Spinner v-if="isLoading" />
      <Errors :errors="errors" />

      <div v-if="draw">
        <div v-if="mode == 'edit'">
          <b-field label="Nazwa losowania">
            <b-input v-model="form.name" />
          </b-field>

          <b-field label="Wiadomosc powitalna">
            <b-input type="textarea" v-model="form.intro" />
          </b-field>

          <h2 class="subtitle pt-4">Jak będzie wyglądać losowanie?</h2>

          <div class="cards mb-3">
            <div
              :class="themeCardClass(theme.id)"
              v-for="theme in themes"
              :key="theme.id"
              @click="form.theme = theme.id"
            >
              <div class="card-image">
                <figure class="image is-4by3">
                  <img :src="'/img/theme/' + theme.id + '/small.png'" :alt="theme.label" />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">{{ theme.label }}</div>
              </div>
            </div>
          </div>

          <b-modal v-model="isThemeModalActive">
            <p class="image">
              <img :src="themeImgLarge" />
            </p>
          </b-modal>

          <b-button @click="isThemeModalActive = true">Podgląd</b-button>

          <h2 class="subtitle pt-4">Kto bierze udział?</h2>

          <b-field>
            <b-input v-model="form.participant"></b-input>
            <b-button @click="addParticipant">Dodaj</b-button>
          </b-field>

          <table class="table is-fullwidth">
            <tr v-for="(participant, i) in form.participants" :key="i">
              <td>{{ participant }}</td>
              <td class="has-text-right">
                <b-button @click="removeParticipant(i)">X</b-button>
              </td>
            </tr>
          </table>

          <p>
            <b-button style="width: 100%" @click="cancel">Odrzuć zmiany</b-button>
          </p>
          <p style="margin-top: 0.5rem">
            <b-button style="width: 100%" @click="update" class="is-primary"
              >Zapisz zmiany</b-button
            >
          </p>
        </div>

        <div v-if="mode == 'view'">
          <p><small>Twoje losowanie</small></p>
          <h1 class="title">{{ draw.name }}</h1>

          <div class="pt-4 pb-5" v-if="draw.started">
            <article class="message is-primary">
              <div class="message-header">
                <p>Link dla uczestników</p>
              </div>
              <div class="message-body">
                <b-field>
                  <b-input id="link" :value="link" />
                  <b-button class="is-primary" @click="copy">Kopiuj</b-button>
                </b-field>
              </div>
            </article>
          </div>

          <div v-if="draw.participants.length === 0">
            <article class="message is-warning">
              <div class="message-body">
                <a @click="edit">Dostosuj losowanie</a>, aby dodać uczestników.
              </div>
            </article>
          </div>

          <div class="pt-4 pb-5" v-if="draw.participants.length > 0">
            <h2 class="subtitle">Uczestnicy ({{ draw.participants.length }})</h2>

            <b-progress
              :value="(countRevealed / draw.participants.length) * 100"
              size="is-medium"
              type="is-info"
              show-value
            >
              {{ countRevealed }} / {{ draw.participants.length }}
            </b-progress>

            <table class="table is-fullwidth">
              <tr v-for="(participant, i) in draw.participants" :key="i">
                <td>
                  {{ participant.name }}
                  <span v-if="participant.revealedAt">&check;</span>
                </td>
              </tr>
            </table>
          </div>

          <div v-if="!draw.started" class="pt-4">
            <p>
              <b-button style="width: 100%" @click="edit">Dostosuj losowanie</b-button>
            </p>
            <p style="margin-top: 0.5rem">
              <b-button style="width: 100%" @click="start" class="is-primary"
                >Rozpocznij losowanie</b-button
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner.vue";
import Errors from "@/components/Organizer/Errors.vue";
import { auth } from "@/api/firebase.js";

export default {
  components: { Spinner, Errors },
  computed: {
    mode() {
      return this.$store.state.organizer.mode;
    },
    draw() {
      return this.$store.state.organizer.draw;
    },
    countRevealed() {
      let count = 0;
      if (!this.$store.state.organizer.draw || !this.$store.state.organizer.draw.participants) {
        return count;
      }

      Object.values(this.$store.state.organizer.draw.participants).forEach(p => {
        if (p.revealedAt) count++;
      });
      return count;
    },
    errors() {
      return this.$store.state.organizer.errors;
    },
    isLoading() {
      return this.$store.state.organizer.loading;
    },
    link() {
      return window.location.origin + "/join/" + this.$route.params.id;
    },
    themeImgLarge() {
      return "/img/theme/" + this.form.theme + "/large.png";
    }
  },
  data() {
    return {
      isThemeModalActive: false,
      themes: [
        // to add a Theme backend must be updated. Search for "const Themes =" in index.js
        // { id: "elves", label: "Elfy" },
        // { id: "ballons", label: "Balony" },
        // { id: "socks", label: "Skarpety" },
        { id: "santa", label: "Mikołaj" },
        { id: "presents", label: "Prezenty" },
        { id: "penguins", label: "Pingwiny" },
        { id: "snowflakes", label: "Śnieg" },
        { id: "drawer", label: "Szuflada" },
        { id: "socks2", label: "Skarpetki" }
      ],
      form: {
        name: "",
        participant: "",
        intro: "",
        participants: [],
        theme: "elves",
        accept: false
      }
    };
  },
  methods: {
    copy() {
      var copyText = document.getElementById("link");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      navigator.clipboard.writeText(copyText.value);
    },
    addParticipant() {
      this.form.participants.push(this.form.participant + "");
      this.form.participant = "";
    },
    removeParticipant(i) {
      this.form.participants = this.form.participants.filter(function(val, index) {
        return i != index;
      });
    },
    edit() {
      const participants = [];
      this.$store.state.organizer.draw.participants.forEach(o => participants.push(o.name));

      this.form.name = this.$store.state.organizer.draw.name;
      this.form.intro = this.$store.state.organizer.draw.intro;
      this.form.theme = this.$store.state.organizer.draw.theme;
      this.form.participants = participants;
      this.$store.dispatch("organizer/edit");
    },
    cancel() {
      this.$store.dispatch("organizer/cancel");
    },
    update() {
      this.$store.dispatch("organizer/update", {
        id: this.$route.params.id,
        form: this.form
      });
    },
    start() {
      this.$buefy.dialog.confirm({
        title: "Uwaga!",
        message:
          "Po rozpoczęciu losowania nie będzie mozna dokonać juz zmian!<br/><br/> Czy na pewno chcesz rozpocząć losowanie?",
        confirmText: "Rozpocznij",
        cancelText: "Anuluj",
        type: "is-danger",
        onConfirm: () => this.$store.dispatch("organizer/start")
      });
    },
    themeCardClass(themeId) {
      let classT = "card theme-" + themeId;
      if (this.form.theme == themeId) {
        return classT + " theme-selected";
      }
      return classT;
    }
  },
  async created() {
    const id = this.$route.params.id;
    this.$store.dispatch("organizer/setToken", await auth.currentUser.getIdToken());
    this.$store.dispatch("organizer/getDraw", { id: id });
  }
};
</script>

<style scoped>
.cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 125px));
  grid-gap: 0.5em;
}

.theme-selected {
  background-color: white;
  color: blueviolet;
  border: 1px solid blueviolet;
  font-weight: bold;
}

.card {
  height: max-content;
}
</style>
