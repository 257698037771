<template>
  <article class="message is-danger" v-if="errors.length">
    <div class="message-header">
      <p>Upss.. mamy problem</p>
    </div>
    <div class="message-body">
      <ul>
        <li v-for="(error, i) in errors" :key="i">- {{ error }}</li>
      </ul>
    </div>
  </article>
</template>

<script>
export default {
  name: "OrganizerErrors",
  props: ['errors']
}
</script>

<style>

</style>
